








































import { Component, Vue } from 'vue-property-decorator';
import { ApiProfile } from '@/scripts/apiProfile';
import * as lib from '@/scripts/apiHelper.ts';
import * as rules from '@/scripts/rules.ts';

@Component({
  components: {}
})
export default class RecoverPassword extends Vue {
  private show = true;
  private loading = false;
  private sendcomplete = false;
  private errors = '';
  private formValid = false;
  private userName = '';

  public created(): void {
    this.formValid = false;
  }

  private hideDialog(): void {
    this.userName = '';
    this.show = false;
  }

  private async send(): Promise<void> {
    this.loading = true;
    this.sendcomplete = false;
    try {
      await ApiProfile.sendRecoverPasswordMail({ UserName: this.userName });
    } catch (error) {
      this.errors = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
      this.sendcomplete = true;
    }
  }

  get emailRules(): ((v: string) => string | boolean)[] {
    return rules.emailRules();
  }
}
